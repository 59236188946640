import Vue from 'vue'
import App from './App'

import store from './store'
import router from "@/router"
import './config/element.js'

import '@/assets/gloabol.css'
// import 'element-ui/lib/theme-chalk/index.css'
// import 'element-ui/lib/theme-chalk/base.css';
import './components/content.less'
import utils from '@/utils/utils'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import JwChat from 'jwchat';
/* 在 0.2.041 之前的版本需要引入 css */
// import 'jwchat/lib/JwChat.css';
Vue.use(JwChat)

Vue.prototype.$utils = utils

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')