import config from "@/config";
// import pako from "pako";
// import {encode,decode} from "base-64";

import QRCode from "qrcode";
import { Message } from "element-ui";

class util {
  checkNum(num) {
    //数字
    const zz = /^[1-9]\d*$/;
    return zz.test(num);
  }
  concatImageUrl(url) {
    //图片拼接
    const baseUrl = config.IMG_BASE_URL;
    const test = /\//;
    const test2 = /\\/;
    if (test.test(url) || test2.test(url)) {
      return url;
    } else {
      return baseUrl + url;
    }
  }

  //获取年
  formateYear(timeStamp) {
    let year = new Date(timeStamp).getFullYear();
    return year;
  }
  //月-日
  formateDate(timeStamp, symbol) {
    let month =
      new Date(timeStamp).getMonth() + 1 < 10
        ? "0" + (new Date(timeStamp).getMonth() + 1)
        : new Date(timeStamp).getMonth() + 1;
    let date =
      new Date(timeStamp).getDate() < 10
        ? "0" + new Date(timeStamp).getDate()
        : new Date(timeStamp).getDate();
    let str = symbol;
    if (symbol) {
      str = symbol;
    } else {
      str = "-";
    }
    return month + str + date;
  }
  //获取年-月-日
  formateTime(timeStamp) {
    const arr = timeStamp.split(" ");
    return arr[0];
  }

  //格式化时间
  dateFormat(timestamp, format = "yyyy-MM-dd hh:mm:ss") {
    let date = new Date(timestamp);
    var o = {
      "y+": date.getFullYear(),
      "M+": date.getMonth() + 1, // 月份 "d+": value.getDate(), // 日
      "d+": date.getDate(),
      "h+": date.getHours(), // 小时 "m+": value.getMinutes(), // 分 "s+": value.getSeconds(), // 秒
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
    };
    if (/(y+)/.test(format)) {
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return format;
  }

  //获取响应信息
  getMsgs(res) {
    var msgs = [];
    if (typeof res.message == "object") {
      for (let key in res.message) {
        msgs.push(res.message[key].join(","));
      }
    } else {
      msgs.push(res.message);
    }
    return msgs;
  }

  //设置标签位置,需要设置 labelPosition 变量
  setlablePos(vueObj) {
    // 监听窗口大小变化，更新labelPosition变量
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        vueObj.labelPosition = "right";
      } else {
        vueObj.labelPosition = "top";
      }
    });
    // 初始化labelPosition变量的值
    if (window.innerWidth >= 1024) {
      vueObj.labelPosition = "right";
    } else {
      vueObj.labelPosition = "top";
    }
  }

  //复制列表项
  copyByItem(taleList, vueObj, conversationId, text) {
    var tales = taleList.filter((p) => p.conversationId == conversationId);
    var textToCopy = text;
    if (tales.length > 0) {
      textToCopy = tales[0].rawText;
    }
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = textToCopy;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);
    vueObj.$message({
      message: "已复制",
      type: "success",
    });
  }

  //时候手机端
  checkIsMobile() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      config.isMobile = true;
    } else {
      config.isMobile = false;
    }
    //console.log("手机端", config.isMobile);
  }

  //获取时间格式
  getNowDate() {
    var now = new Date();
    var year = now.getFullYear().toString(); // 获取年份，并转为字符串
    var month = (now.getMonth() + 1).toString().padStart(2, "0"); // 获取月份，并转为字符串，不足两位数用 0 填充
    var day = now.getDate().toString().padStart(2, "0"); // 获取日期，并转为字符串，不足两位数用 0 填充
    var hours = now.getHours().toString().padStart(2, "0"); // 获取小时数，并转为字符串，不足两位数用 0 填充
    var minutes = now.getMinutes().toString().padStart(2, "0"); // 获取分钟数，并转为字符串，不足两位数用 0 填
    var seconds = now.getSeconds().toString().padStart(2, "0"); // 获取秒数，并转为字符串，不足两位数用 0 填充
    var dateStr = year + "-" + month + "-" + day; //化的日期字符串
    var timeStr = hours + ":" + minutes + ":" + seconds; // 拼接成格式化的时间字符串
    return dateStr + " " + timeStr;
  }

  //生成二维码
  async generateQRCode(qrCodeUrl, width) {
    try {
      const canvas = await QRCode.toCanvas(qrCodeUrl, { width: width || 200 });
      return canvas.toDataURL();
    } catch (error) {
      console.error(error);
    }
  }
  //获取地址栏参数
  getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  }
  //是否是微信浏览器
  isWechat() {
    return /MicroMessenger/i.test(window.navigator.userAgent);
  }
  //复制
  handCopy(url) {
    if (!url) {
      return;
    }
    let domInput = document.createElement("input");
    domInput.value = url;
    document.body.appendChild(domInput); // 添加input节点
    domInput.select(); // 选择对象;
    document.execCommand("Copy"); // 执行浏览器复制命令
    Message.success("复制成功");
    domInput.remove();
  }
  downloadFile(url) {
    // 创建一个隐藏的 iframe 元素
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    // 加载要下载的文件
    iframe.src = url;
  }
  downloadFileXHR(url, fileName) {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], {
          type: "application/octet-stream",
        });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(downloadUrl);
      }
    };
    xhr.send();
  }
  downloadFileTagA(url, fileName) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(downloadUrl);
  }
}

export default new util();
