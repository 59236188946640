<template>
  <router-view />
</template>

<script>
import utils from "@/utils/utils";
export default {
  name: "App",
  components: {},
  methods: {},
  created() {
    // this.$router.replace('/index')
		utils.checkIsMobile()
  },
};
</script>

<style lang="less">
/*公共css */
@import "@/assets/public.less";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50;
  margin-top: 60px; */
}
/* body::-webkit-scrollbar {
	display: none;
} */
</style>