import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index"),
    children: [],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login"),
  },
  {
    path: "/authCallback",
    name: "authCallback",
    component: () => import("@/views/user/authCallback"),
  },
  {
    path: "/agent",
    name: "agent",
    component: () => import("@/views/info/agent"),
  },
  {
    path: "/agentMobile",
    name: "agentMobile",
    component: () => import("@/views/info/agentMobile"),
  },
];

Vue.use(VueRouter);
const router = new VueRouter({
  routes,
  mode: "history",
});

// 前置守卫
router.beforeEach((to, from, next) => {
  // 使用 router.resolve() 检查即将访问的路由是否存在
  const resolved = router.resolve(to.path);
  if (resolved.resolved.matched.length > 0) {
    // 路由存在，继续导航
    next();
  } else {
    //debugger;
    // 路由不存在，跳转到指定页面
    next("/"); // 这里将 '/not-found' 替换为你要跳转的路径
  }
});

export default router;
