/*element根据需求引入*/
import Vue from 'vue'

import {
	Carousel,
	CarouselItem,
  Message,
	Image,
	Tabs,
	TabPane,
	Breadcrumb,
	BreadcrumbItem,
	Icon,
	Pagination,
} from 'element-ui'

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Icon)
Vue.use(Pagination)

Vue.prototype.$message = Message;