const config = {

	BASE_URL: process.env.VUE_APP_BASE_URL,
	BASE_URL_WS: process.env.VUE_APP_BASE_URL_WS,

	COMPANY_NAME: '公司名称',

	copyright: '',

	devcompinfo: '',
	
	isMobile:false,
}

export default config